import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

import '../assets/css/404.css'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404 Ups..." />
      <Layout>
        <section className="not-found bg-dark single-column">
          <h1 title="404" className="glitch">
            404
          </h1>
          <p className="not-found-sub h4">
            Wygląda na to, że nic tutaj nie ma...
          </p>

          <p className="text-light">
            Sprawdź, czy wpisany adres jest poprawny, wróć do poprzedniej strony
            lub daj znać co poszło nie tak.
          </p>
          <div className="center">
            <Link className="btn" to="/">
              Home
            </Link>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default NotFoundPage
